<template>
  <!--begin::Error-->
  <div
    class="d-flex flex-column flex-column-fluid position-relative"
    style="background-color: #EAF9FC;"
  >
    <!--begin::Background-->
    <div
      class="bgi-size-contain bgi-position-x-end bgi-position-y-bottom bgi-no-repeat position-absolute top-0 start-0 w-100 h-100"
      style="background-color: #F6E0A4;"
    />
    <!--end::Background-->

    <!--begin::Content-->
    <div class="px-10 px-md-20 pt-5 pt-md-10 z-index-1">
      <router-link to="/">
        <img
          class="mh-50px"
          alt="Agroru.com"
          src="/media/logos/logo-vector.svg"
        />
      </router-link>
    </div>

    <div
      v-if="errorType === '403'"
      class="z-index-2 h-100 d-flex align-items-center flex-column"
    >
      <div
        class="
          px-10
          px-md-20
          py-10
          py-md-0
          d-flex
          flex-row
          justify-content-md-center
          align-items-center
          flex-root
          z-index-1
        "
      >
        <img alt="404" src="media/svg/errors/403.svg" class="w-100 h-150px" />
      </div>

      <el-row class="pb-sm-20">
        <el-col :gutter="20" :sm="24" :md="12" class="d-flex flex-column justify-content-end">
          <div class="d-flex flex-column flex-row-auto px-10 px-md-20">
            <p class="display-6 fw-bolder text-gray-800 mb-8">
              У вас не хватает прав для просмотра этой страницы.
            </p>
            <router-link
              :to="{ name: 'dashboards' }"
              class="btn btn-primary fs-6 fw-bolder py-4 px-6 mr-auto"
            >
              На главную
            </router-link>
          </div>
        </el-col>

        <el-col :xs="24" :sm="12" class="d-flex flex-column justify-content-end">
          <div class="px-10 px-md-20">
            <img alt="Трактор" src="media/svg/errors/tractor.svg" class="h-250px" />
          </div>
        </el-col>
      </el-row>
    </div>

    <div
      v-else-if="errorType === '404'"
      class="z-index-2 h-100 d-flex align-items-center flex-column"
    >
      <div
        class="
          px-10
          px-md-20
          py-10
          py-md-0
          d-flex
          flex-row
          justify-content-md-center
          align-items-center
          flex-root
          z-index-1
        "
      >
        <img alt="404" src="media/svg/errors/404.svg" class="w-100 h-150px" />
      </div>

      <el-row class="pb-sm-20">
        <el-col :gutter="20" :sm="24" :md="12" class="d-flex flex-column justify-content-end">
          <div class="d-flex flex-column flex-row-auto px-10 px-md-20">
            <p class="display-6 fw-bolder text-gray-800 mb-8">
              Такой страницы не существует.
            </p>
            <router-link
              :to="{ name: 'dashboards' }"
              class="btn btn-primary fs-6 fw-bolder py-4 px-6 mr-auto"
            >
              На главную
            </router-link>
          </div>
        </el-col>

        <el-col :xs="24" :sm="12" class="d-flex flex-column justify-content-end">
          <div class="px-10 px-md-20">
            <img alt="Трактор" src="media/svg/errors/tractor.svg" class="h-250px" />
          </div>
        </el-col>
      </el-row>
    </div>

    <!--end::Content-->
  </div>
  <!--end::Error-->
</template>

<script>
import { defineComponent } from 'vue';
import router from '@/router/index';

export default defineComponent({
  name: 'error-403',
  components: {},
  computed: {
    errorType() {
      console.log(
        'router.currentRoute.value.meta.type ::',
        router.currentRoute.value.meta.type === '404',
        router.currentRoute.value.meta.type === '403'
      );
      return router.currentRoute.value.meta.type;
    },
  },
});
</script>
